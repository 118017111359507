import React from "react";
/* eslint-disable max-len */

import { useStaticQuery, graphql, Link } from "gatsby";

import SEO from "../../components/Seo";
import BlogPage from "../../components/template-parts/BlogPage";
import { ImgContent } from "../../components/template-parts/BlogPage/styles";

function Psique() {
	const { banner, livro } = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "curso/banner10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      livro: file(
        relativePath: { eq: "books/psique-e-medicina-tradicional-chinesa-3-edicao.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

	const bannerPage = banner.childImageSharp.fluid;
	const content = {
		title: `Psique e Medicina Tradicional Chinesa`,
		featuredImage: bannerPage,
	};
	return (
		<>
			<SEO
				title="Psique e Medicina Tradicional Chinesa"
				keywords="Psique e Medicina Tradicional Chinesa, helena campiglia, helenacampiglia, medica clinica geral, medicaclinicageral, acupuntura, infertilidade, mente e corpo, meditação, fmusp"
				description="Os temas são discutidos a cada capítulo visando tanto a leitura de pacientes e leigos, quanto a de acupunturistas especializados na área, começando com os conceitos mais abrangentes e acessíveis a todos e aprofundando-se, quando necessário, nos detalhes do tratamento de cada uma dessas fases da vida da mulher."
			/>
			<BlogPage content={content}>
				<p>
					O conjunto indivisível psique e corpo, cujo delicado equilíbrio tem seu fio condutor no Qi
					(energia); os aspectos do espírito (Shen) e a consciência do si mesmo como eixo orientador
					da psique e da saúde global do corpo; os tipos psicológicos e caracterológicos
					relacionados aos elementos da{" "}
					<Link to="/medicina-tradicional-chinesa">Medicina Chinesa</Link> (Água, Madeira, Fogo,
					Terra e Metal) e as teorias sobre a psique de Freud, Reich e Jung, apresentadas no intuito
					de traçar um paralelo entre a Psicologia e a{" "}
					<Link to="/medicina-tradicional-chinesa">Medicina Chinesa</Link>;
				</p>
				<div>
					<ImgContent fluid={livro.childImageSharp.fluid} />
				</div>
				<p>
					Como faziam os antigos médicos chineses para entender a psique e tratar seus
					desequilíbrios? Que relação existe entre a Psicologia Moderna e a milenar Medicina
					Tradicional Chinesa?
				</p>
				<p>
					As respostas a tais perguntas são amplamente exploradas neste livro que mostra como a
					Medicina Tradicional Chinesa entende e trata as alterações do psiquismo, revelando ainda a
					relação que existe entre a visão chinesa antiga e a Psicologia atual. Para tanto, a autora
					aborda assuntos como:Lista
				</p>
				<ul>
					<li>
						as teorias sobre a psique de Freud, Reich e Jung, apresentadas no intuito de traçar um
						paralelo entre a Psicologia e a Medicina Chinesa;
					</li>
					<li>
						os aspectos do espírito (Shen) e a consciência do si mesmo como eixo orientador da
						psique e da saúde global do corpo;{" "}
					</li>
					<li>
						os tipos psicológicos e caracterológicos relacionados aos elementos da{" "}
						<Link to="/medicina-tradicional-chinesa">Medicina Chinesa</Link> (Água, Madeira, Fogo,
						Terra e Metal);{" "}
					</li>
					<li>
						o conjunto indivisível psique e corpo, cujo delicado equilíbrio tem seu fio condutor no
						Qi (energia) e, finalmente;
					</li>
					<li>
						o diagnóstico, a compreensão e o tratamento das doenças psíquicas através da acupuntura,
						fitoterapia, dietoterapia, meditação e terapias corporais.
					</li>
				</ul>
				<div className="button">
					<Link
						to="https://amzn.to/3yPeyNw"
						target="_blank">
						<span>Compre Agora!</span>
					</Link>
				</div>
			</BlogPage>
		</>
	);
}

export default Psique;
